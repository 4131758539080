import * as React from "react";
import { Link } from "gatsby";
import Layout from "../templates/layout";
import { useEffect } from "react";
import { simpleRequest } from "../utils/api";
import { PreviewBanner, usePreview } from "../templates/preview";
import Header from "../templates/app-header";
import Helmet from "react-helmet";
import Seo from "../components/seo";
import Footer from "../templates/app-footer";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

const dummyObj = {};

// markup
const NotFoundPage = () => {
  const { pageData, isLoading } = usePreview(dummyObj);
  useEffect(() => {
    (async () => {
      let slug = document.location.pathname.slice(1);
      if (slug.endsWith("/")) slug = slug.slice(0, -1);
      const url = "/pages/" + window.encodeURIComponent(slug);
      await simpleRequest({ url, method: "GET" });
    })().catch(console.error);
  }, []);
  if (pageData && pageData.body && pageData.title && !isLoading) {
    return (
      <div>
        <div>
          <PreviewBanner />
        </div>
        <div className="header">
          <Header />
        </div>
        <div className="main">
          <div className="page-content">
            <div dangerouslySetInnerHTML={{ __html: pageData.body }} />
            <Helmet title={pageData.title} meta={pageData.metaTagsObj || []} />
            <Seo />
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
  return (
    <Layout>
      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code style={codeStyles}>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
