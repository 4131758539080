import * as React from "react";

import Header from "./app-header";
import Footer from "./app-footer";
import Seo from "../components/seo";

const Layout = ({ children, newFooter }) => {
  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <div className="main">{children}</div>
      <div className="footer">
        <Footer newFooter={newFooter} />
      </div>
      <Seo />
    </div>
  );
};

export default Layout;
